import React from "react";
import { StyledButtonWhatsapp, StyledButtonWhatsappIcon, StyledButtonWhatsappText } from "./ButtonWhatsapp.styles";

const ButtonWhatsapp = ({onClick}) => {
    return (
        <StyledButtonWhatsapp onClick={onClick}>
            <StyledButtonWhatsappIcon src="img/whatsapp.png" alt="whatsapp"/>
            <StyledButtonWhatsappText>Reservá ahora</StyledButtonWhatsappText>
        </StyledButtonWhatsapp>
    );
};

export default ButtonWhatsapp;