import { styled } from "../../theme/styled";

export const StyledButtonWhatsapp = styled("div", {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    padding: "10px 20px",
    borderRadius: "36px",
    backgroundColor: "$whatsapp",
    margin: "20px 0",
    cursor: "pointer",
    "@media (max-width: 720px)": {
        padding: "8px 20px",
        gap: "8px",
    },
});

export const StyledButtonWhatsappIcon = styled("img", {
    width: "28px",
    height: "28px",
    "@media (max-width: 720px)": {
        width: "28px",
        height: "28px",
    },
});

export const StyledButtonWhatsappText = styled("span", {
    fontSize: "20px",
    fontWeight: "500",
    color: "$white",
    "@media (max-width: 720px)": {
        fontSize: "18px",
    },
});